import { select } from 'd3';
import { useEffect, useRef, useState } from 'react';
import io from 'socket.io-client';
import { GameState, Stone } from './common/go';
import { backendURL, SocketEV } from './common/util';
import './go.css';
import GoD3 from './ts/go-d3';
import useWindowDimensions from './ts/useWindowDimensions';

function Go() {
  const socket = useRef(io(backendURL)).current;

  const { width: windowWidth, height: windowHeight } = useWindowDimensions();
  const [boardWidth, setBoardWidth] = useState<number>();

  const svgBoardRef = useRef<SVGSVGElement>(null);
  const svgStonesRef = useRef<SVGSVGElement>(null);

  const stonesRef = useRef<Stone[]>([]);

  const goD3 = useRef(new GoD3(19, 50, 22, socket)).current;

  const currColorRef = useRef<HTMLSpanElement>(null);
  const switchColorRef = useRef<HTMLSpanElement>(null);

  const syncColor = () => {
    if (currColorRef.current)
      currColorRef.current.style.background = goD3.currColor;

    if (switchColorRef.current)
      switchColorRef.current.style.background = goD3.switchColor
        ? '#BFEFFF'
        : 'lightgray';
  };

  useEffect(() => setBoardWidth(Math.min(windowWidth, windowHeight) - 5), [
    windowWidth,
    windowHeight
  ]);

  useEffect(() => {
    goD3.drawBoard(select(svgBoardRef.current));

    socket.on(SocketEV.GAME_STATE, (newGameState: GameState) => {
      stonesRef.current = newGameState.state.flat();

      if (newGameState.lastStone)
        newGameState.state[newGameState.lastStone.y][
          newGameState.lastStone.x
        ].highlighted = true;

      if (goD3.switchColor && newGameState.lastStone?.color) {
        goD3.setColor(newGameState.lastStone.color);
        goD3.toggleColor();
      }

      goD3.drawStones(select(svgStonesRef.current), stonesRef.current);

      syncColor();
    });
  }, [goD3, socket]);

  const toggleColor = () => {
    goD3.toggleColor();
    goD3.drawStones(select(svgStonesRef.current), stonesRef.current);
    syncColor();
  };

  const toggleSwitchColor = () => {
    goD3.toggleSwitchColor();
    goD3.drawStones(select(svgStonesRef.current), stonesRef.current);
    syncColor();
  };

  const forward = () => socket.emit(SocketEV.OP, { type: 'forward' });

  const undo = () => socket.emit(SocketEV.OP, { type: 'undo' });

  const reset = () => {
    goD3.setColor('black');
    socket.emit(SocketEV.OP, { type: 'reset' });
  };

  return (
    <div className='go-wrapper'>
      <div className='go-menu'>
        <span ref={currColorRef} onClick={() => toggleColor()}></span>
        <span ref={switchColorRef} onClick={() => toggleSwitchColor()}>
          switch
        </span>
        <div style={{ display: 'flex' }}>
          <button onClick={() => undo()} style={{ flex: 1 }}>
            undo
          </button>
          <button onClick={() => forward()} style={{ flex: 1 }}>
            forward
          </button>
        </div>
        <button onClick={() => reset()}>reset</button>
      </div>
      <div>
        <svg
          width={boardWidth}
          height={boardWidth}
          viewBox={`0 0 ${goD3.boardWidth} ${goD3.boardWidth}`}
          preserveAspectRatio='xMidYMid meet'
          style={{ backgroundColor: 'lightgray' }}>
          <g ref={svgBoardRef}></g>
          <g ref={svgStonesRef}></g>
        </svg>
      </div>
    </div>
  );
}

export default Go;
