export const isDev =
  !process.env.NODE_ENV || process.env.NODE_ENV === 'development';

export const localhost = '127.0.0.1';

export const backendPort = isDev ? 3000 : 8080;

export const backendURL = isDev
  ? `hq:${backendPort}`
  : 'https://go-backend.mofu.dev';

export const frontendURL = isDev ? 'hq' : 'https://go.mofu.dev';

export enum SocketEV {
  CONNECTION = 'connection',
  GAME_STATE = 'game-state',
  OP = 'operation',
  DISCONNECT = 'disconnect'
}

export function jsonfy(obj: object): string {
  return JSON.stringify(obj, null, 2);
}

export function dateStr(date: Date) {
  return date.toLocaleString('en-us', { timeZoneName: 'short' });
}

export function duration(from: Date, to: Date): string {
  const milliseconds = to.getTime() - from.getTime();
  const h = Math.floor(milliseconds / 3600000);
  const m = Math.floor((milliseconds / 60000) % 60);
  const s = (milliseconds / 1000) % 60;
  return `${h}:${m}:${s}`;
}
