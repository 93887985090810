import { select } from 'd3';
import { Selection } from 'd3-selection';
import * as go from '../common/go';
import { SocketEV } from '../common/util';

type SVG = Selection<SVGSVGElement | null, unknown, null, undefined>;
type G = Selection<SVGGElement, unknown, null, undefined>;
type Direction = 'horizontal' | 'vertical';

export default class GoD3 {
  zeroToBoardSize: number[];
  labelWidth: number;
  stoneR: number;
  stoneD: number;
  lineWidth: number;
  boardWidth: number;
  linePos: number[];

  currColor: go.StoneColor;
  switchColor: boolean;

  socket: SocketIOClient.Socket;

  constructor(
    boardSize: number,
    labelWidth: number,
    stoneR: number,
    socket: SocketIOClient.Socket
  ) {
    this.zeroToBoardSize = Array.from(Array(boardSize).keys());
    this.labelWidth = labelWidth;
    this.stoneR = stoneR;
    this.stoneD = stoneR * 2;
    this.lineWidth = this.stoneD * (boardSize - 1);
    this.boardWidth = (labelWidth + stoneR) * 2 + this.lineWidth;
    this.linePos = this.zeroToBoardSize.map(
      idx => labelWidth + stoneR + this.stoneD * idx
    );
    this.currColor = 'black';
    this.switchColor = true;
    this.socket = socket;
  }

  setColor(color: go.StoneColor) {
    this.currColor = color;
  }

  toggleColor() {
    this.currColor = go.toggleColor(this.currColor);
  }

  toggleSwitchColor() {
    this.switchColor = !this.switchColor;
  }

  xToCX(x: number) {
    return this.labelWidth + this.stoneR + this.stoneD * x;
  }

  yToCY(y: number) {
    return this.labelWidth + this.stoneR + this.stoneD * (go.boardSize - y - 1);
  }

  drawLines(g: G, direction: Direction) {
    const partial = g
      .selectAll('line')
      .data(this.linePos)
      .enter()
      .append('line')
      .style('stroke', 'black')
      .style('stroke-width', 1)
      .style('shape-rendering', 'crispEdges');

    if (direction === 'horizontal') {
      partial
        .attr('x1', this.labelWidth + this.stoneR)
        .attr('x2', this.labelWidth + this.stoneR + this.lineWidth)
        .attr('y1', p => p)
        .attr('y2', p => p);
    } else {
      partial
        .attr('x1', p => p)
        .attr('x2', p => p)
        .attr('y1', this.labelWidth + this.stoneR)
        .attr('y2', this.labelWidth + this.stoneR + this.lineWidth);
    }
  }

  drawLabels(
    g: G,
    label: Array<number | string>,
    alignAt: number,
    direction: Direction
  ) {
    const partial = g
      .selectAll('text')
      .data(this.zeroToBoardSize)
      .enter()
      .append('text')
      .style('font-size', `${this.labelWidth * 0.5}px`)
      .style('font-family', 'Consolas')
      .text(idx => label[idx]);

    if (direction === 'horizontal') {
      partial
        .attr('x', idx => this.linePos[idx])
        .attr('y', alignAt)
        .attr('dx', this.labelWidth * -0.125)
        .attr('dy', this.labelWidth * 0.65);
    } else {
      partial
        .attr('x', alignAt)
        .attr('y', idx => this.linePos[idx])
        .attr('dx', this.labelWidth * 0.2)
        .attr('dy', this.labelWidth * 0.15);
    }
  }

  drawBoard(svg: SVG) {
    svg.selectChildren().remove();

    this.drawLines(svg.append('g'), 'horizontal');
    this.drawLines(svg.append('g'), 'vertical');

    this.drawLabels(svg.append('g'), go.xLabel, 0, 'horizontal');
    this.drawLabels(
      svg.append('g'),
      go.xLabel,
      this.boardWidth - this.labelWidth,
      'horizontal'
    );

    this.drawLabels(svg.append('g'), go.yLabel, 0, 'vertical');
    this.drawLabels(
      svg.append('g'),
      go.yLabel,
      this.boardWidth - this.labelWidth,
      'vertical'
    );

    svg
      .append('g')
      .selectAll('circle')
      .data(go.dots)
      .join('circle')
      .attr('r', this.stoneR * 0.2)
      .attr('cx', dot => this.xToCX(dot.x))
      .attr('cy', dot => this.xToCX(dot.y))
      .attr('fill', 'black');
  }

  drawStones(svg: SVG, stones: go.Stone[]) {
    svg
      .selectAll('circle')
      .data(stones)
      .join('circle')
      .attr('r', this.stoneR * 0.95)
      .attr('cx', stone => this.xToCX(stone.x))
      .attr('cy', stone => this.yToCY(stone.y))
      .attr('fill', stone => (stone.color ? stone.color : this.currColor))
      .attr('fill-opacity', stone => (stone.onBoard ? 1 : 0))
      .attr('stroke', stone => (stone.highlighted ? 'red' : 'black'))
      .attr('stroke-opacity', stone =>
        stone.onBoard || stone.highlighted ? 1 : 0
      )
      .on('click', null)
      .on('mouseout', null)
      .on('mouseover', null)
      .each((datum, idx, nodes) => {
        const curr = select(nodes[idx]);

        if (!datum.onBoard) {
          curr
            .on('mouseover', () => curr.attr('fill-opacity', 0.5))
            .on('mouseout', () => curr.attr('fill-opacity', 0))
            .on('click', () => {
              curr
                .on('click', null)
                .on('mouseout', null)
                .on('mouseover', null)
                .attr('fill-opacity', 1)
                .attr('stroke', 'red')
                .attr('stroke-opacity', 1);

              this.socket.emit(SocketEV.OP, {
                type: 'add',
                stone: { x: datum.x, y: datum.y, color: this.currColor }
              });

              if (this.switchColor) this.toggleColor();
            });
        }
      });
  }
}
