export const boardSize = 19;

export const xLabel = [
  'A',
  'B',
  'C',
  'D',
  'E',
  'F',
  'G',
  'H', // skip I
  'J',
  'K',
  'L',
  'M',
  'N',
  'O',
  'P',
  'Q',
  'R',
  'S',
  'T'
];

// [19, 1]
export const yLabel = Array.from(xLabel.keys(), idx => idx + 1).reverse();

export type StoneColor = 'black' | 'white';

export function toggleColor(color: StoneColor): StoneColor {
  return color === 'black' ? 'white' : 'black';
}

export interface Stone {
  x: number;
  y: number;
  color?: StoneColor;
  onBoard?: boolean;
  highlighted?: boolean;
}

export const dots: Stone[] = [
  { x: 3, y: 3, color: 'black', onBoard: true },
  { x: 3, y: 9, color: 'black', onBoard: true },
  { x: 3, y: 15, color: 'black', onBoard: true },
  { x: 9, y: 3, color: 'black', onBoard: true },
  { x: 9, y: 9, color: 'black', onBoard: true },
  { x: 9, y: 15, color: 'black', onBoard: true },
  { x: 15, y: 3, color: 'black', onBoard: true },
  { x: 15, y: 9, color: 'black', onBoard: true },
  { x: 15, y: 15, color: 'black', onBoard: true }
];

export class GameState {
  state: Stone[][];
  lastStone?: Stone;

  constructor(other?: GameState) {
    this.state = [];

    if (other && other.lastStone) this.lastStone = other.lastStone;

    for (let y = 0; y < boardSize; ++y) {
      this.state[y] = [];

      for (let x = 0; x < boardSize; ++x) {
        if (other) {
          // deep copy
          this.state[y][x] = {
            x: x,
            y: y,
            color: other.state[y][x].color,
            onBoard: other.state[y][x].onBoard,
            highlighted: other.state[y][x].highlighted
          };
        } else {
          this.state[y][x] = {
            x: x,
            y: y,
            onBoard: false,
            highlighted: false
          };
        }
      } // end x
    } // end y
  } // end constructor
}

export type OPType =
  | 'add'
  | 'delete'
  | 'highlight'
  | 'un-highlight'
  | 'forward'
  | 'undo'
  | 'reset';

export interface Operation {
  type: OPType;
  stone?: Stone;
}

export interface Transition {
  toAdd: Stone[];
  toDelete: Stone[];
  toHighlight: Stone[];
  toUnHighlight: Stone[];
}

export class Timeline {
  idx: number;
  len: number;
  transitions: Transition[];
  currState: GameState;

  constructor() {
    this.idx = -1;
    this.len = 0;
    this.transitions = [];
    this.currState = new GameState();
  }

  next(op: Operation) {
    let newState = new GameState(this.currState);

    if (op.type === 'reset') {
      this.idx = -1;
      this.len = 0;
      this.transitions = [];
      this.currState = new GameState();
      return;
    } else if (op.type === 'forward' && this.idx + 1 < this.len) {
      newState = this.apply(newState, this.transitions[++this.idx]);
    } else if (op.type === 'undo' && this.idx >= 0) {
      newState = this.apply(newState, {
        toAdd: this.transitions[this.idx].toDelete,
        toDelete: this.transitions[this.idx].toAdd,
        toHighlight: this.transitions[this.idx].toUnHighlight,
        toUnHighlight: this.transitions[this.idx].toHighlight
      });

      --this.idx;
    } else if (op.stone) {
      let transition: Transition = {
        toAdd: [],
        toDelete: [],
        toHighlight: [],
        toUnHighlight: []
      };

      switch (op.type) {
        case 'add':
          transition.toAdd.push({
            x: op.stone.x,
            y: op.stone.y,
            color: op.stone.color
          });
          break;
        case 'delete':
          transition.toDelete.push({ x: op.stone.x, y: op.stone.y });
          break;
        case 'highlight':
          transition.toHighlight.push({ x: op.stone.x, y: op.stone.y });
          break;
        case 'un-highlight':
          transition.toUnHighlight.push({ x: op.stone.x, y: op.stone.y });
          break;
        default:
          return;
      }

      this.transitions[++this.idx] = transition;
      this.len = this.idx + 1;
      newState = this.apply(newState, transition);
    }

    this.currState = newState;
  }

  apply(gameState: GameState, transition: Transition) {
    for (const stone of transition.toDelete) {
      gameState.state[stone.y][stone.x] = { x: stone.x, y: stone.y }; // reset
      gameState.lastStone = {
        x: stone.x,
        y: stone.y,
        color: stone.color ? toggleColor(stone.color) : stone.color
      };
    }

    for (const stone of transition.toAdd) {
      gameState.state[stone.y][stone.x].color = stone.color;
      gameState.state[stone.y][stone.x].onBoard = true;
      gameState.lastStone = { x: stone.x, y: stone.y, color: stone.color };
    }

    for (const stone of transition.toHighlight)
      gameState.state[stone.y][stone.x].highlighted = true;

    for (const stone of transition.toUnHighlight)
      gameState.state[stone.y][stone.x].highlighted = false;

    return gameState;
  }
}
